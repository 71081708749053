import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import MainBox from '../components/MainBox';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <MainBox maxWidth={840}>
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that doesn&#39;t exist... the sadness. Try some of
        our popular destinations to see if you can find what you were looking
        for.
        <ul>
          <li>
            <Link to="/">Homepage</Link>
          </li>
          <li>
            <Link to="/blog/20201028/introducing-the-sjgar-stack">
              Introducing the SJGAR Stack
            </Link>
          </li>
        </ul>
      </p>
    </MainBox>
  </Layout>
);

export default NotFoundPage;
